export default [
  {
    header: 'Others',
  },
  {
    title: 'Profile',
    route: 'studentDetail',
    icon: 'ShieldIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Documents',
    route: 'access-control',
    icon: 'ShieldIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'University-section',
    route: 'access-control',
    icon: 'ShieldIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
]
